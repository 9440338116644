<template>
  <div class="login_content_sty">
    <div class="login_main_content_sty">
      <div class="left_content_sty" style="width: 683px;">
        <!-- <img style="width: 683px;" src="@/assets/img/loginBg/login_bg.png" /> -->
      </div>
      <div class="right_content_sty">
        <ul class="tab_content_sty">
          <li
            :class="[{ checked: checkedId == i.id }]"
            v-for="i in tabList"
            :key="i.id"
            @click="checkedId = i.id"
          >
            {{ i.name }}
          </li>
        </ul>
        <div class="input_content_sty" v-if="checkedId == 11">
          <div class="account_sty">
            <input
              type="text"
              placeholder="请输入手机号"
              v-model="params.mobile"
              maxlength="11"
            />
            <i
              class="iconfont clean_icon_sty"
              v-show="canBeClear"
              @click="clearMobile"
              >&#xe628;</i
            >
          </div>
          <div class="code_content_sty">
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="params.code"
            />
            <div
              class="code_btn_sty"
              @click="getCode"
              :class="[{ sending_sty: !isSending }]"
            >
              {{ countNum }}
            </div>
          </div>
          <!-- <div class="invide_code_sty">
            <input
              type="text"
              placeholder="选填邀请码"
              v-model="params.inviteCode"
            />
            <i
              class="iconfont clean_icon_sty"
              v-show="canBeClearInvideCode"
              @click="clearInvideCode"
              >&#xe628;</i
            >
          </div> -->
          <p class="go_reg">没有账号？<router-link class="go" :to="{ path: '/register' }">去注册</router-link></p>
          <div class="login_btn_sty" @click="login">登录</div>
        </div>
        <div class="input_content_sty" v-else>
          <div class="account_sty">
            <input
              type="text"
              placeholder="请输入手机号/学号"
              v-model="accountParams.loginName"
              maxlength="11"
            />
            <i
              class="iconfont clean_icon_sty"
              v-show="canBeClearName"
              @click="clearName"
              >&#xe628;</i
            >
          </div>
          <div class="invide_code_sty">
            <input
              type="password"
              placeholder="请输入密码"
              v-model="accountParams.password"
            />
            <i
              class="iconfont clean_icon_sty"
              v-show="canBeClearPassword"
              @click="clearPassword"
              >&#xe628;</i
            >
          </div>
          <p class="go_reg">没有账号？<router-link class="go" :to="{ path: '/register' }">去注册</router-link></p>
          <div class="login_btn_sty" @click="accountLogin">登录</div>
        </div>
        <div class="agreement_text_sty">
         登录即表示您已阅读并同意
          <router-link
            tag="span"
            to="/helpCenter?option=2&path=registerAgreement"
          >
            《注册协议》</router-link
          >
          <router-link
            tag="span"
            to="/helpCenter?option=3&path=serviceAgreement"
            >《服务协议》</router-link
          >
          <router-link tag="span" to="/helpCenter?option=4&path=privacyPolicy"
            >《隐私政策》
          </router-link>
        </div>
      </div>
    </div>
    <div class="bottom_test_sty">
      <p>
        <!-- 厦门易普道信息科技有限公司 地址：厦门市思明区软件园观日路8号305 -->
        <router-link tag="span" to="/">网站首页</router-link>
        <router-link tag="span" to="/allCourses">全部课程</router-link>
        <!-- <router-link tag="span" to="/">我的学习</router-link> -->
        <router-link tag="span" to="/helpCenter">帮助中心</router-link>
      </p>
      <!-- <p>Copyright 2020-2021 京ICP备11017824号</p> -->
    </div>
  </div>
</template>

<script>
import { getCode, login, accountLogin } from "@/api/login";
export default {
  data() {
    const tabList = [
        {
          id: 12,
          name: "密码登录",
        }
      ]
      let checkedId = 12
      if (process.env.VUE_APP_PRIVATE != 'true') {
        tabList.unshift({
          id: 11,
          name: "验证码登录",
        })
        checkedId = 11
      }

    return {
      local: process.env.VUE_APP_PRIVATE == 'true',
      tabList,
      checkedId,
      countNum: "获取验证码",
      params: {
        mobile: "",
        code: "",
        inviteCode: "",
        password: "",
      },
      accountParams: {
        loginName: "",
        password: "",
      },
      timer: null,
      isSending: false,
      canBeClear: false,
      canBeClearInvideCode: false,
      canBeClearPassword: false,
      canBeClearName: false,
      num:60,
      oneClick:true
    };
  },
  watch: {
    "params.mobile": function () {
      if (this.params.mobile !== "") {
        this.canBeClear = true;
      } else {
        this.canBeClear = false;
      }
    },
    "params.inviteCode": function () {
      if (this.params.inviteCode !== "") {
        this.canBeClearInvideCode = true;
      } else {
        this.canBeClearInvideCode = false;
      }
    },
    "accountParams.loginName": function () {
      if (this.accountParams.loginName !== "") {
        this.canBeClearName = true;
      } else {
        this.canBeClearName = false;
      }
    },
    "accountParams.password": function () {
      if (this.accountParams.password !== "") {
        this.canBeClearPassword = true;
      } else {
        this.canBeClearPassword = false;
      }
    },
  },
  created() {},
  methods: {
    getCode() {
      if (this.params.mobile.trim() == "") {
        this.$message({
          type: "warning",
          message: "请输入手机号",
        });
        return;
      }
      if(this.num > 0 && this.num < 60) {
          return false;
      }
      if(!this.oneClick) {
          return false;
      }
      this.oneClick = false;
      this.timer = setInterval(() => {
        this.num--;
        this.oneClick = true;
        this.countNum = this.num + "s后重发";
        this.isSending = true;
        if (this.num < 1) {
          this.countNum = "重新获取验证码";
          this.num = 60;
          clearInterval(this.timer);
          this.isSending = false;
        }
      }, 1000);
      getCode(this.params).then((json) => {
        const { code, msg } = json;
        if (code === 200) {
          this.$message({
            type: "success",
            message: "发送成功",
          });
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    clearMobile() {
      this.params.mobile = "";
    },
    clearInvideCode() {
      this.params.inviteCode = "";
    },
    clearPassword() {
      this.accountParams.password = "";
    },
    clearName() {
      this.accountParams.loginName = "";
    },
    login() {
      if (this.params.mobile.trim == "") {
        this.$message({
          type: "warning",
          message: "登录手机号不能为空",
        });
        return false;
      }
      if (
        !/^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/.test(
          this.params.mobile
        )
      ) {
        this.$message({
          type: "warning",
          message: "手机号格式不对",
        });
        return false;
      }
      if (this.params.code == "") {
        this.$message({
          type: "warning",
          message: "手机验证码不能为空",
        });
        return false;
      }
      login(this.params).then((json) => {
        const { code, msg } = json;
        if (code === 200) {
          this.$router.push("/");
          localStorage.setItem("token", json.data.token);
          this.$message({
            type: "success",
            message: "登录成功",
          });
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    accountLogin() {
      if (this.accountParams.loginName.trim() == "") {
        this.$message({
          type: "warning",
          message: "登录名不能为空",
        });
        return false;
      }
      if (this.accountParams.password.trim() == "") {
        this.$message({
          type: "warning",
          message: "登录密码不能为空",
        });
        return false;
      }
      accountLogin(this.accountParams).then((json) => {
        const { code, msg } = json;
        if (code === 200) {
          this.$router.push("/");
          localStorage.setItem("token", json.data.token);
          this.$message({
            type: "success",
            message: "登录成功",
          });
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_content_sty {
  background: #0d79ff;
  height: 100vh;
  min-height: 813px;
  min-width: 1200px;
  position: relative;
  .login_main_content_sty {
    width: 1200px;
    margin: auto;
    height: 500px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    .left_content_sty {
      display: inline-block;
    }
    .right_content_sty {
      display: inline-block;
      width: 460px;
      height: 500px;
      background: #ffffff;
      border-radius: 6px;
      vertical-align: top;
      position: relative;
      .tab_content_sty {
        width: 100%;
        margin-top: 51px;
        padding: 0 65px;
        box-sizing: border-box;
        text-align: center;
        & > li {
          display: inline-block;
          font-size: 24px;
          color: #999999;
          font-weight: 500;
          text-align: center;
          width: 50%;
          cursor: pointer;
        }
      }
      .agreement_text_sty {
        width: 341px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: 47px;
        & > span {
          color: #0d79ff;
          cursor: pointer;
        }
      }
    }
  }
}
.input_content_sty {
  padding: 0 65px;
  margin-top: 46px;
  .account_sty {
    width: 100%;
    height: 48px;
    background: #f6f6f6;
    border-radius: 24px;
    position: relative;
    .clean_icon_sty {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      color: #bdbdbd;
      cursor: pointer;
    }
    & > input {
      height: 48px;
      line-height: 48px;
      padding: 0 23px;
      box-sizing: border-box;
    }
  }
  .invide_code_sty {
    width: 100%;
    height: 48px;
    background: #f6f6f6;
    border-radius: 24px;
    position: relative;
    margin-top: 22px;
    .clean_icon_sty {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      color: #bdbdbd;
      cursor: pointer;
    }
    & > input {
      height: 48px;
      line-height: 48px;
      padding: 0 23px;
      box-sizing: border-box;
    }
  }
  .code_content_sty {
    width: 100%;
    height: 48px;
    background: #f6f6f6;
    border-radius: 24px;
    margin-top: 22px;
    position: relative;
    & > input {
      height: 48px;
      line-height: 48px;
      padding: 0 23px;
      box-sizing: border-box;
    }
    .code_btn_sty {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #333;
    }
  }
  .go_reg {
    margin-top: 30px;
    text-align: center;
    .go {
      color: #0d79ff;
    }
  }
  .login_btn_sty {
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: #0d79ff;
    border-radius: 24px;
    color: #fff;
    font-size: 18px;
    margin-top: 30px;
    text-align: center;
    cursor: pointer;
  }
}
.checked {
  color: #333 !important;
  position: relative;
  &::after {
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
    width: 46px;
    height: 4px;
    background: #0d79ff;
    content: "";
  }
}
.bottom_test_sty {
  color: #fff;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 44px;
  font-size: 14px;
  span {
    cursor: pointer;
    margin-right: 4px;
  }
  & > p:last-child {
    font-size: 12px;
  }
}
.sending_sty {
  color: #0d79ff !important;
}
</style>